.to_open_game {
    animation: heart-pulse 1s infinite linear;
}
  
@keyframes heart-pulse {
    80% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1.2);
    }
}