.heart {
    animation: heart-pulse 1s infinite ease-out;
}
  
@keyframes heart-pulse {
0% {
    transform: scale(0.5);
}
50% {
    transform: scale(1.1);
}
70% {
    transform: scale(0.8);
}
100% {
    transform: scale(0.5);
}
}

/* .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}    */